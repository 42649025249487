import { default as _91id_93MpvTKRvrNMMeta } from "/app/pages/[id].vue?macro=true";
import { default as appealsIi4TQ5YAzFMeta } from "/app/pages/adv/appeals.vue?macro=true";
import { default as balanceBW0Szs9khNMeta } from "/app/pages/adv/balance.vue?macro=true";
import { default as busketKQUXIAUX5FMeta } from "/app/pages/adv/busket.vue?macro=true";
import { default as catalogG9L9CtDBxxMeta } from "/app/pages/adv/catalog.vue?macro=true";
import { default as _91id_93VJEHJPQlgPMeta } from "/app/pages/adv/creative/[id].vue?macro=true";
import { default as create4zlCh7QjbsMeta } from "/app/pages/adv/creative/create.vue?macro=true";
import { default as indexvqeWLkzmJoMeta } from "/app/pages/adv/creative/index.vue?macro=true";
import { default as creativespXDjaB9488Meta } from "/app/pages/adv/creatives.vue?macro=true";
import { default as index47WxGddgHRMeta } from "/app/pages/adv/dialogs/index.vue?macro=true";
import { default as mainl1DDF5o615Meta } from "/app/pages/adv/main.vue?macro=true";
import { default as newsXgtn6WaVMZMeta } from "/app/pages/adv/news.vue?macro=true";
import { default as notificationskEeXWDY8nNMeta } from "/app/pages/adv/notifications.vue?macro=true";
import { default as indexc1EjbZiz8SMeta } from "/app/pages/adv/order/index.vue?macro=true";
import { default as _91id_931qH7beuE1eMeta } from "/app/pages/adv/orders/[id].vue?macro=true";
import { default as index2omqfFH8lHMeta } from "/app/pages/adv/orders/index.vue?macro=true";
import { default as indexlVgiOJSNLuMeta } from "/app/pages/adv/place/index.vue?macro=true";
import { default as settingsfgDRNZmaKSMeta } from "/app/pages/adv/settings.vue?macro=true";
import { default as supportx8NU9nO1vBMeta } from "/app/pages/adv/support.vue?macro=true";
import { default as balanceQaPywToU55Meta } from "/app/pages/balance.vue?macro=true";
import { default as creativesBaSyqVZAZyMeta } from "/app/pages/creatives.vue?macro=true";
import { default as depositLK2Y2mAC0KMeta } from "/app/pages/deposit.vue?macro=true";
import { default as _91id_93MTc9cEKfEoMeta } from "/app/pages/group/telegram/[id].vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as loginhHM0vSTW5jMeta } from "/app/pages/login.vue?macro=true";
import { default as channelseLJF2tQcGWMeta } from "/app/pages/moderation/channels.vue?macro=true";
import { default as clientswg6o8J4OzfMeta } from "/app/pages/moderation/clients.vue?macro=true";
import { default as indexAEKDYc6ZGEMeta } from "/app/pages/moderation/index.vue?macro=true";
import { default as ordersZwKeZatEw9Meta } from "/app/pages/moderation/orders.vue?macro=true";
import { default as publishers9wGKRdPWOxMeta } from "/app/pages/moderation/publishers.vue?macro=true";
import { default as settingscN43BG6zESMeta } from "/app/pages/moderation/settings.vue?macro=true";
import { default as statistics9EzMUZpDq0Meta } from "/app/pages/moderation/statistics.vue?macro=true";
import { default as supportHEvj7Sz0sgMeta } from "/app/pages/moderation/support.vue?macro=true";
import { default as newsWdQv0BaSvYMeta } from "/app/pages/news.vue?macro=true";
import { default as indexBpuRKxqKBqMeta } from "/app/pages/orders/[id]/index.vue?macro=true";
import { default as indexG1Ehu1sqkUMeta } from "/app/pages/orders/index.vue?macro=true";
import { default as indexDBqUP2kL3WMeta } from "/app/pages/publisher/dialogs/index.vue?macro=true";
import { default as indexESc4xL4MjJMeta } from "/app/pages/publisher/history/index.vue?macro=true";
import { default as index8EwMPkoZPEMeta } from "/app/pages/publisher/index.vue?macro=true";
import { default as MobilePlaceVxsuIdn1uYMeta } from "/app/pages/publisher/MobilePlace.vue?macro=true";
import { default as indexuLrKPWzmMoMeta } from "/app/pages/publisher/orders/index.vue?macro=true";
import { default as payments_oldXh5moIP5VUMeta } from "/app/pages/publisher/payments_old.vue?macro=true";
import { default as indexH0o24Sv740Meta } from "/app/pages/publisher/payments/index.vue?macro=true";
import { default as place_oldxiId7YejKxMeta } from "/app/pages/publisher/place_old.vue?macro=true";
import { default as _91id_93J7VzEtDjH6Meta } from "/app/pages/publisher/place/[id].vue?macro=true";
import { default as _91id_93nkis4gcu8oMeta } from "/app/pages/publisher/places/[id].vue?macro=true";
import { default as _91id_93pPiA8CQkw0Meta } from "/app/pages/publisher/places/moderate/[id].vue?macro=true";
import { default as newpcwIWraKLDMeta } from "/app/pages/publisher/places/new.vue?macro=true";
import { default as old_45ideo1KLccjE4Meta } from "/app/pages/publisher/places/old-id.vue?macro=true";
import { default as placeScriptT90MuEKQtVMeta } from "/app/pages/publisher/placeScript.ts?macro=true";
import { default as publicationsonHifNIaUlMeta } from "/app/pages/publisher/publications.vue?macro=true";
import { default as index5gtUUpjnjtMeta } from "/app/pages/publisher/settings/index.vue?macro=true";
import { default as indexeLQfWt3RcvMeta } from "/app/pages/publisher/showcase/index.vue?macro=true";
import { default as sergeyohi7GU4LzFMeta } from "/app/pages/sergey.vue?macro=true";
import { default as settingsLwEYOlkQxrMeta } from "/app/pages/settings.vue?macro=true";
import { default as supportPKo8CaxFTuMeta } from "/app/pages/support.vue?macro=true";
import { default as confirms4Kmv58cXaMeta } from "/app/pages/user/confirm.vue?macro=true";
export default [
  {
    name: _91id_93MpvTKRvrNMMeta?.name ?? "id",
    path: _91id_93MpvTKRvrNMMeta?.path ?? "/:id()",
    meta: _91id_93MpvTKRvrNMMeta || {},
    alias: _91id_93MpvTKRvrNMMeta?.alias || [],
    redirect: _91id_93MpvTKRvrNMMeta?.redirect,
    component: () => import("/app/pages/[id].vue").then(m => m.default || m)
  },
  {
    name: appealsIi4TQ5YAzFMeta?.name ?? "adv-appeals",
    path: appealsIi4TQ5YAzFMeta?.path ?? "/adv/appeals",
    meta: appealsIi4TQ5YAzFMeta || {},
    alias: appealsIi4TQ5YAzFMeta?.alias || [],
    redirect: appealsIi4TQ5YAzFMeta?.redirect,
    component: () => import("/app/pages/adv/appeals.vue").then(m => m.default || m)
  },
  {
    name: balanceBW0Szs9khNMeta?.name ?? "adv-balance",
    path: balanceBW0Szs9khNMeta?.path ?? "/adv/balance",
    meta: balanceBW0Szs9khNMeta || {},
    alias: balanceBW0Szs9khNMeta?.alias || [],
    redirect: balanceBW0Szs9khNMeta?.redirect,
    component: () => import("/app/pages/adv/balance.vue").then(m => m.default || m)
  },
  {
    name: busketKQUXIAUX5FMeta?.name ?? "adv-busket",
    path: busketKQUXIAUX5FMeta?.path ?? "/adv/busket",
    meta: busketKQUXIAUX5FMeta || {},
    alias: busketKQUXIAUX5FMeta?.alias || [],
    redirect: busketKQUXIAUX5FMeta?.redirect,
    component: () => import("/app/pages/adv/busket.vue").then(m => m.default || m)
  },
  {
    name: catalogG9L9CtDBxxMeta?.name ?? "adv-catalog",
    path: catalogG9L9CtDBxxMeta?.path ?? "/adv/catalog",
    meta: catalogG9L9CtDBxxMeta || {},
    alias: catalogG9L9CtDBxxMeta?.alias || [],
    redirect: catalogG9L9CtDBxxMeta?.redirect,
    component: () => import("/app/pages/adv/catalog.vue").then(m => m.default || m)
  },
  {
    name: _91id_93VJEHJPQlgPMeta?.name ?? "adv-creative-id",
    path: _91id_93VJEHJPQlgPMeta?.path ?? "/adv/creative/:id()",
    meta: _91id_93VJEHJPQlgPMeta || {},
    alias: _91id_93VJEHJPQlgPMeta?.alias || [],
    redirect: _91id_93VJEHJPQlgPMeta?.redirect,
    component: () => import("/app/pages/adv/creative/[id].vue").then(m => m.default || m)
  },
  {
    name: create4zlCh7QjbsMeta?.name ?? "adv-creative-create",
    path: create4zlCh7QjbsMeta?.path ?? "/adv/creative/create",
    meta: create4zlCh7QjbsMeta || {},
    alias: create4zlCh7QjbsMeta?.alias || [],
    redirect: create4zlCh7QjbsMeta?.redirect,
    component: () => import("/app/pages/adv/creative/create.vue").then(m => m.default || m)
  },
  {
    name: indexvqeWLkzmJoMeta?.name ?? "adv-creative",
    path: indexvqeWLkzmJoMeta?.path ?? "/adv/creative",
    meta: indexvqeWLkzmJoMeta || {},
    alias: indexvqeWLkzmJoMeta?.alias || [],
    redirect: indexvqeWLkzmJoMeta?.redirect,
    component: () => import("/app/pages/adv/creative/index.vue").then(m => m.default || m)
  },
  {
    name: creativespXDjaB9488Meta?.name ?? "adv-creatives",
    path: creativespXDjaB9488Meta?.path ?? "/adv/creatives",
    meta: creativespXDjaB9488Meta || {},
    alias: creativespXDjaB9488Meta?.alias || [],
    redirect: creativespXDjaB9488Meta?.redirect,
    component: () => import("/app/pages/adv/creatives.vue").then(m => m.default || m)
  },
  {
    name: index47WxGddgHRMeta?.name ?? "adv-dialogs",
    path: index47WxGddgHRMeta?.path ?? "/adv/dialogs",
    meta: index47WxGddgHRMeta || {},
    alias: index47WxGddgHRMeta?.alias || [],
    redirect: index47WxGddgHRMeta?.redirect,
    component: () => import("/app/pages/adv/dialogs/index.vue").then(m => m.default || m)
  },
  {
    name: mainl1DDF5o615Meta?.name ?? "adv-main",
    path: mainl1DDF5o615Meta?.path ?? "/adv/main",
    meta: mainl1DDF5o615Meta || {},
    alias: mainl1DDF5o615Meta?.alias || [],
    redirect: mainl1DDF5o615Meta?.redirect,
    component: () => import("/app/pages/adv/main.vue").then(m => m.default || m)
  },
  {
    name: newsXgtn6WaVMZMeta?.name ?? "adv-news",
    path: newsXgtn6WaVMZMeta?.path ?? "/adv/news",
    meta: newsXgtn6WaVMZMeta || {},
    alias: newsXgtn6WaVMZMeta?.alias || [],
    redirect: newsXgtn6WaVMZMeta?.redirect,
    component: () => import("/app/pages/adv/news.vue").then(m => m.default || m)
  },
  {
    name: notificationskEeXWDY8nNMeta?.name ?? "adv-notifications",
    path: notificationskEeXWDY8nNMeta?.path ?? "/adv/notifications",
    meta: notificationskEeXWDY8nNMeta || {},
    alias: notificationskEeXWDY8nNMeta?.alias || [],
    redirect: notificationskEeXWDY8nNMeta?.redirect,
    component: () => import("/app/pages/adv/notifications.vue").then(m => m.default || m)
  },
  {
    name: indexc1EjbZiz8SMeta?.name ?? "adv-order",
    path: indexc1EjbZiz8SMeta?.path ?? "/adv/order",
    meta: indexc1EjbZiz8SMeta || {},
    alias: indexc1EjbZiz8SMeta?.alias || [],
    redirect: indexc1EjbZiz8SMeta?.redirect,
    component: () => import("/app/pages/adv/order/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_931qH7beuE1eMeta?.name ?? "adv-orders-id",
    path: _91id_931qH7beuE1eMeta?.path ?? "/adv/orders/:id()",
    meta: _91id_931qH7beuE1eMeta || {},
    alias: _91id_931qH7beuE1eMeta?.alias || [],
    redirect: _91id_931qH7beuE1eMeta?.redirect,
    component: () => import("/app/pages/adv/orders/[id].vue").then(m => m.default || m)
  },
  {
    name: index2omqfFH8lHMeta?.name ?? "adv-orders",
    path: index2omqfFH8lHMeta?.path ?? "/adv/orders",
    meta: index2omqfFH8lHMeta || {},
    alias: index2omqfFH8lHMeta?.alias || [],
    redirect: index2omqfFH8lHMeta?.redirect,
    component: () => import("/app/pages/adv/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexlVgiOJSNLuMeta?.name ?? "adv-place",
    path: indexlVgiOJSNLuMeta?.path ?? "/adv/place",
    meta: indexlVgiOJSNLuMeta || {},
    alias: indexlVgiOJSNLuMeta?.alias || [],
    redirect: indexlVgiOJSNLuMeta?.redirect,
    component: () => import("/app/pages/adv/place/index.vue").then(m => m.default || m)
  },
  {
    name: settingsfgDRNZmaKSMeta?.name ?? "adv-settings",
    path: settingsfgDRNZmaKSMeta?.path ?? "/adv/settings",
    meta: settingsfgDRNZmaKSMeta || {},
    alias: settingsfgDRNZmaKSMeta?.alias || [],
    redirect: settingsfgDRNZmaKSMeta?.redirect,
    component: () => import("/app/pages/adv/settings.vue").then(m => m.default || m)
  },
  {
    name: supportx8NU9nO1vBMeta?.name ?? "adv-support",
    path: supportx8NU9nO1vBMeta?.path ?? "/adv/support",
    meta: supportx8NU9nO1vBMeta || {},
    alias: supportx8NU9nO1vBMeta?.alias || [],
    redirect: supportx8NU9nO1vBMeta?.redirect,
    component: () => import("/app/pages/adv/support.vue").then(m => m.default || m)
  },
  {
    name: balanceQaPywToU55Meta?.name ?? "balance",
    path: balanceQaPywToU55Meta?.path ?? "/balance",
    meta: balanceQaPywToU55Meta || {},
    alias: balanceQaPywToU55Meta?.alias || [],
    redirect: balanceQaPywToU55Meta?.redirect,
    component: () => import("/app/pages/balance.vue").then(m => m.default || m)
  },
  {
    name: creativesBaSyqVZAZyMeta?.name ?? "creatives",
    path: creativesBaSyqVZAZyMeta?.path ?? "/creatives",
    meta: creativesBaSyqVZAZyMeta || {},
    alias: creativesBaSyqVZAZyMeta?.alias || [],
    redirect: creativesBaSyqVZAZyMeta?.redirect,
    component: () => import("/app/pages/creatives.vue").then(m => m.default || m)
  },
  {
    name: depositLK2Y2mAC0KMeta?.name ?? "deposit",
    path: depositLK2Y2mAC0KMeta?.path ?? "/deposit",
    meta: depositLK2Y2mAC0KMeta || {},
    alias: depositLK2Y2mAC0KMeta?.alias || [],
    redirect: depositLK2Y2mAC0KMeta?.redirect,
    component: () => import("/app/pages/deposit.vue").then(m => m.default || m)
  },
  {
    name: _91id_93MTc9cEKfEoMeta?.name ?? "group-telegram-id",
    path: _91id_93MTc9cEKfEoMeta?.path ?? "/group/telegram/:id()",
    meta: _91id_93MTc9cEKfEoMeta || {},
    alias: _91id_93MTc9cEKfEoMeta?.alias || [],
    redirect: _91id_93MTc9cEKfEoMeta?.redirect,
    component: () => import("/app/pages/group/telegram/[id].vue").then(m => m.default || m)
  },
  {
    name: indexN6pT4Un8hYMeta?.name ?? "index",
    path: indexN6pT4Un8hYMeta?.path ?? "/",
    meta: indexN6pT4Un8hYMeta || {},
    alias: indexN6pT4Un8hYMeta?.alias || [],
    redirect: indexN6pT4Un8hYMeta?.redirect,
    component: () => import("/app/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginhHM0vSTW5jMeta?.name ?? "login",
    path: loginhHM0vSTW5jMeta?.path ?? "/login",
    meta: loginhHM0vSTW5jMeta || {},
    alias: loginhHM0vSTW5jMeta?.alias || [],
    redirect: loginhHM0vSTW5jMeta?.redirect,
    component: () => import("/app/pages/login.vue").then(m => m.default || m)
  },
  {
    name: channelseLJF2tQcGWMeta?.name ?? "moderation-channels",
    path: channelseLJF2tQcGWMeta?.path ?? "/moderation/channels",
    meta: channelseLJF2tQcGWMeta || {},
    alias: channelseLJF2tQcGWMeta?.alias || [],
    redirect: channelseLJF2tQcGWMeta?.redirect,
    component: () => import("/app/pages/moderation/channels.vue").then(m => m.default || m)
  },
  {
    name: clientswg6o8J4OzfMeta?.name ?? "moderation-clients",
    path: clientswg6o8J4OzfMeta?.path ?? "/moderation/clients",
    meta: clientswg6o8J4OzfMeta || {},
    alias: clientswg6o8J4OzfMeta?.alias || [],
    redirect: clientswg6o8J4OzfMeta?.redirect,
    component: () => import("/app/pages/moderation/clients.vue").then(m => m.default || m)
  },
  {
    name: indexAEKDYc6ZGEMeta?.name ?? "moderation",
    path: indexAEKDYc6ZGEMeta?.path ?? "/moderation",
    meta: indexAEKDYc6ZGEMeta || {},
    alias: indexAEKDYc6ZGEMeta?.alias || [],
    redirect: indexAEKDYc6ZGEMeta?.redirect,
    component: () => import("/app/pages/moderation/index.vue").then(m => m.default || m)
  },
  {
    name: ordersZwKeZatEw9Meta?.name ?? "moderation-orders",
    path: ordersZwKeZatEw9Meta?.path ?? "/moderation/orders",
    meta: ordersZwKeZatEw9Meta || {},
    alias: ordersZwKeZatEw9Meta?.alias || [],
    redirect: ordersZwKeZatEw9Meta?.redirect,
    component: () => import("/app/pages/moderation/orders.vue").then(m => m.default || m)
  },
  {
    name: publishers9wGKRdPWOxMeta?.name ?? "moderation-publishers",
    path: publishers9wGKRdPWOxMeta?.path ?? "/moderation/publishers",
    meta: publishers9wGKRdPWOxMeta || {},
    alias: publishers9wGKRdPWOxMeta?.alias || [],
    redirect: publishers9wGKRdPWOxMeta?.redirect,
    component: () => import("/app/pages/moderation/publishers.vue").then(m => m.default || m)
  },
  {
    name: settingscN43BG6zESMeta?.name ?? "moderation-settings",
    path: settingscN43BG6zESMeta?.path ?? "/moderation/settings",
    meta: settingscN43BG6zESMeta || {},
    alias: settingscN43BG6zESMeta?.alias || [],
    redirect: settingscN43BG6zESMeta?.redirect,
    component: () => import("/app/pages/moderation/settings.vue").then(m => m.default || m)
  },
  {
    name: statistics9EzMUZpDq0Meta?.name ?? "moderation-statistics",
    path: statistics9EzMUZpDq0Meta?.path ?? "/moderation/statistics",
    meta: statistics9EzMUZpDq0Meta || {},
    alias: statistics9EzMUZpDq0Meta?.alias || [],
    redirect: statistics9EzMUZpDq0Meta?.redirect,
    component: () => import("/app/pages/moderation/statistics.vue").then(m => m.default || m)
  },
  {
    name: supportHEvj7Sz0sgMeta?.name ?? "moderation-support",
    path: supportHEvj7Sz0sgMeta?.path ?? "/moderation/support",
    meta: supportHEvj7Sz0sgMeta || {},
    alias: supportHEvj7Sz0sgMeta?.alias || [],
    redirect: supportHEvj7Sz0sgMeta?.redirect,
    component: () => import("/app/pages/moderation/support.vue").then(m => m.default || m)
  },
  {
    name: newsWdQv0BaSvYMeta?.name ?? "news",
    path: newsWdQv0BaSvYMeta?.path ?? "/news",
    meta: newsWdQv0BaSvYMeta || {},
    alias: newsWdQv0BaSvYMeta?.alias || [],
    redirect: newsWdQv0BaSvYMeta?.redirect,
    component: () => import("/app/pages/news.vue").then(m => m.default || m)
  },
  {
    name: indexBpuRKxqKBqMeta?.name ?? "orders-id",
    path: indexBpuRKxqKBqMeta?.path ?? "/orders/:id()",
    meta: indexBpuRKxqKBqMeta || {},
    alias: indexBpuRKxqKBqMeta?.alias || [],
    redirect: indexBpuRKxqKBqMeta?.redirect,
    component: () => import("/app/pages/orders/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexG1Ehu1sqkUMeta?.name ?? "orders",
    path: indexG1Ehu1sqkUMeta?.path ?? "/orders",
    meta: indexG1Ehu1sqkUMeta || {},
    alias: indexG1Ehu1sqkUMeta?.alias || [],
    redirect: indexG1Ehu1sqkUMeta?.redirect,
    component: () => import("/app/pages/orders/index.vue").then(m => m.default || m)
  },
  {
    name: indexDBqUP2kL3WMeta?.name ?? "publisher-dialogs",
    path: indexDBqUP2kL3WMeta?.path ?? "/publisher/dialogs",
    meta: indexDBqUP2kL3WMeta || {},
    alias: indexDBqUP2kL3WMeta?.alias || [],
    redirect: indexDBqUP2kL3WMeta?.redirect,
    component: () => import("/app/pages/publisher/dialogs/index.vue").then(m => m.default || m)
  },
  {
    name: indexESc4xL4MjJMeta?.name ?? "publisher-history",
    path: indexESc4xL4MjJMeta?.path ?? "/publisher/history",
    meta: indexESc4xL4MjJMeta || {},
    alias: indexESc4xL4MjJMeta?.alias || [],
    redirect: indexESc4xL4MjJMeta?.redirect,
    component: () => import("/app/pages/publisher/history/index.vue").then(m => m.default || m)
  },
  {
    name: index8EwMPkoZPEMeta?.name ?? "publisher",
    path: index8EwMPkoZPEMeta?.path ?? "/publisher",
    meta: index8EwMPkoZPEMeta || {},
    alias: index8EwMPkoZPEMeta?.alias || [],
    redirect: index8EwMPkoZPEMeta?.redirect,
    component: () => import("/app/pages/publisher/index.vue").then(m => m.default || m)
  },
  {
    name: MobilePlaceVxsuIdn1uYMeta?.name ?? "publisher-MobilePlace",
    path: MobilePlaceVxsuIdn1uYMeta?.path ?? "/publisher/MobilePlace",
    meta: MobilePlaceVxsuIdn1uYMeta || {},
    alias: MobilePlaceVxsuIdn1uYMeta?.alias || [],
    redirect: MobilePlaceVxsuIdn1uYMeta?.redirect,
    component: () => import("/app/pages/publisher/MobilePlace.vue").then(m => m.default || m)
  },
  {
    name: indexuLrKPWzmMoMeta?.name ?? "publisher-orders",
    path: indexuLrKPWzmMoMeta?.path ?? "/publisher/orders",
    meta: indexuLrKPWzmMoMeta || {},
    alias: indexuLrKPWzmMoMeta?.alias || [],
    redirect: indexuLrKPWzmMoMeta?.redirect,
    component: () => import("/app/pages/publisher/orders/index.vue").then(m => m.default || m)
  },
  {
    name: payments_oldXh5moIP5VUMeta?.name ?? "publisher-payments_old",
    path: payments_oldXh5moIP5VUMeta?.path ?? "/publisher/payments_old",
    meta: payments_oldXh5moIP5VUMeta || {},
    alias: payments_oldXh5moIP5VUMeta?.alias || [],
    redirect: payments_oldXh5moIP5VUMeta?.redirect,
    component: () => import("/app/pages/publisher/payments_old.vue").then(m => m.default || m)
  },
  {
    name: indexH0o24Sv740Meta?.name ?? "publisher-payments",
    path: indexH0o24Sv740Meta?.path ?? "/publisher/payments",
    meta: indexH0o24Sv740Meta || {},
    alias: indexH0o24Sv740Meta?.alias || [],
    redirect: indexH0o24Sv740Meta?.redirect,
    component: () => import("/app/pages/publisher/payments/index.vue").then(m => m.default || m)
  },
  {
    name: place_oldxiId7YejKxMeta?.name ?? "publisher-place_old",
    path: place_oldxiId7YejKxMeta?.path ?? "/publisher/place_old",
    meta: place_oldxiId7YejKxMeta || {},
    alias: place_oldxiId7YejKxMeta?.alias || [],
    redirect: place_oldxiId7YejKxMeta?.redirect,
    component: () => import("/app/pages/publisher/place_old.vue").then(m => m.default || m)
  },
  {
    name: _91id_93J7VzEtDjH6Meta?.name ?? "publisher-place-id",
    path: _91id_93J7VzEtDjH6Meta?.path ?? "/publisher/place/:id()",
    meta: _91id_93J7VzEtDjH6Meta || {},
    alias: _91id_93J7VzEtDjH6Meta?.alias || [],
    redirect: _91id_93J7VzEtDjH6Meta?.redirect,
    component: () => import("/app/pages/publisher/place/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93nkis4gcu8oMeta?.name ?? "publisher-places-id",
    path: _91id_93nkis4gcu8oMeta?.path ?? "/publisher/places/:id()",
    meta: _91id_93nkis4gcu8oMeta || {},
    alias: _91id_93nkis4gcu8oMeta?.alias || [],
    redirect: _91id_93nkis4gcu8oMeta?.redirect,
    component: () => import("/app/pages/publisher/places/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93pPiA8CQkw0Meta?.name ?? "publisher-places-moderate-id",
    path: _91id_93pPiA8CQkw0Meta?.path ?? "/publisher/places/moderate/:id()",
    meta: _91id_93pPiA8CQkw0Meta || {},
    alias: _91id_93pPiA8CQkw0Meta?.alias || [],
    redirect: _91id_93pPiA8CQkw0Meta?.redirect,
    component: () => import("/app/pages/publisher/places/moderate/[id].vue").then(m => m.default || m)
  },
  {
    name: newpcwIWraKLDMeta?.name ?? "publisher-places-new",
    path: newpcwIWraKLDMeta?.path ?? "/publisher/places/new",
    meta: newpcwIWraKLDMeta || {},
    alias: newpcwIWraKLDMeta?.alias || [],
    redirect: newpcwIWraKLDMeta?.redirect,
    component: () => import("/app/pages/publisher/places/new.vue").then(m => m.default || m)
  },
  {
    name: old_45ideo1KLccjE4Meta?.name ?? "publisher-places-old-id",
    path: old_45ideo1KLccjE4Meta?.path ?? "/publisher/places/old-id",
    meta: old_45ideo1KLccjE4Meta || {},
    alias: old_45ideo1KLccjE4Meta?.alias || [],
    redirect: old_45ideo1KLccjE4Meta?.redirect,
    component: () => import("/app/pages/publisher/places/old-id.vue").then(m => m.default || m)
  },
  {
    name: placeScriptT90MuEKQtVMeta?.name ?? "publisher-placeScript",
    path: placeScriptT90MuEKQtVMeta?.path ?? "/publisher/placeScript",
    meta: placeScriptT90MuEKQtVMeta || {},
    alias: placeScriptT90MuEKQtVMeta?.alias || [],
    redirect: placeScriptT90MuEKQtVMeta?.redirect,
    component: () => import("/app/pages/publisher/placeScript.ts").then(m => m.default || m)
  },
  {
    name: publicationsonHifNIaUlMeta?.name ?? "publisher-publications",
    path: publicationsonHifNIaUlMeta?.path ?? "/publisher/publications",
    meta: publicationsonHifNIaUlMeta || {},
    alias: publicationsonHifNIaUlMeta?.alias || [],
    redirect: publicationsonHifNIaUlMeta?.redirect,
    component: () => import("/app/pages/publisher/publications.vue").then(m => m.default || m)
  },
  {
    name: index5gtUUpjnjtMeta?.name ?? "publisher-settings",
    path: index5gtUUpjnjtMeta?.path ?? "/publisher/settings",
    meta: index5gtUUpjnjtMeta || {},
    alias: index5gtUUpjnjtMeta?.alias || [],
    redirect: index5gtUUpjnjtMeta?.redirect,
    component: () => import("/app/pages/publisher/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexeLQfWt3RcvMeta?.name ?? "publisher-showcase",
    path: indexeLQfWt3RcvMeta?.path ?? "/publisher/showcase",
    meta: indexeLQfWt3RcvMeta || {},
    alias: indexeLQfWt3RcvMeta?.alias || [],
    redirect: indexeLQfWt3RcvMeta?.redirect,
    component: () => import("/app/pages/publisher/showcase/index.vue").then(m => m.default || m)
  },
  {
    name: sergeyohi7GU4LzFMeta?.name ?? "sergey",
    path: sergeyohi7GU4LzFMeta?.path ?? "/sergey",
    meta: sergeyohi7GU4LzFMeta || {},
    alias: sergeyohi7GU4LzFMeta?.alias || [],
    redirect: sergeyohi7GU4LzFMeta?.redirect,
    component: () => import("/app/pages/sergey.vue").then(m => m.default || m)
  },
  {
    name: settingsLwEYOlkQxrMeta?.name ?? "settings",
    path: settingsLwEYOlkQxrMeta?.path ?? "/settings",
    meta: settingsLwEYOlkQxrMeta || {},
    alias: settingsLwEYOlkQxrMeta?.alias || [],
    redirect: settingsLwEYOlkQxrMeta?.redirect,
    component: () => import("/app/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: supportPKo8CaxFTuMeta?.name ?? "support",
    path: supportPKo8CaxFTuMeta?.path ?? "/support",
    meta: supportPKo8CaxFTuMeta || {},
    alias: supportPKo8CaxFTuMeta?.alias || [],
    redirect: supportPKo8CaxFTuMeta?.redirect,
    component: () => import("/app/pages/support.vue").then(m => m.default || m)
  },
  {
    name: confirms4Kmv58cXaMeta?.name ?? "user-confirm",
    path: confirms4Kmv58cXaMeta?.path ?? "/user/confirm",
    meta: confirms4Kmv58cXaMeta || {},
    alias: confirms4Kmv58cXaMeta?.alias || [],
    redirect: confirms4Kmv58cXaMeta?.redirect,
    component: () => import("/app/pages/user/confirm.vue").then(m => m.default || m)
  }
]