import { io, Socket } from "socket.io-client";

export default defineNuxtPlugin((nuxtApp) => {
    const token = useCookie('authorizedP').value;

    const socketConfig = {
        auth: {
            token: token
        },
        extraHeaders: {
            Authorization: `Bearer ${token}`
        },
    };

    const socket = io("https://socket.telepost.pro/chat", socketConfig);

    socket.on('connect', () => {
        console.log('Connected to WebSocket server');
    });

    // Подключение к пространству имен 'ticket'
    const ticketSocket = io("https://socket.telepost.pro/ticket", socketConfig);

    ticketSocket.on('connect', () => {
        console.log('Connected to ticket namespace');
    });

    // ticketSocket.emit('joinGlobal');

    // ticketSocket.on('newTicketMessage', (data: any) => {
    //     console.log("newTicketMessage received:", data);
    // });

    ticketSocket.on('connect_error', (error: Error) => {
        console.error('Ticket socket connection error:', error);
        console.error('Error details:', {
            message: error.message,
            stack: error.stack
        });
    });

    ticketSocket.on('error', (error: Error) => {
        console.error('Ticket socket error:', error);
        console.error('Error details:', {
            message: error.message,
            stack: error.stack
        });
    });

    ticketSocket.on('syncMessage', (data: any) => {
        console.log("syncMessage received:", data);
    });

    socket.on('connect_error', (err: Error) => {
        console.error('Connection error:', err);
        console.error('Error details:', {
            message: err.message,
            stack: err.stack
        });
    });

    socket.on('disconnect', (reason) => {
        console.log('Disconnected:', reason);
    });

    socket.on('syncMessage', (data: any) => {
        console.log('syncMessage', data);
    });

    // Предоставляем доступ к обоим сокетам
    nuxtApp.provide("socket", socket);
    nuxtApp.provide("ticketSocket", ticketSocket);
});
